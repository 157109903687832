export class Constants {
    public static readonly TOKEN_NAME = 'TOKEN';
    public static readonly REFRESH_TOKEN_NAME = 'REFRESH_TOKEN';
    public static readonly USER = 'USER';
    public static readonly LN_PRODUCT_LINE = 'finance_ln_productLine';
    public static readonly LN_CUSTOMER_LIST = 'finance_ln_customerList';
    public static readonly LN_ITEM = 'finance_covisianSalesContract';
    public static readonly LN_SALES_ORDER = 'finance_covisianSalesOrder';
    public static readonly SP_SAVE_SALES_ORDERS = 'Finance/saveSellingOrder';
    public static readonly XML_STRIP_REGEX = /^\s*<[^>]+>(.*)\s*<\/\w+>$/s;
}

export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
