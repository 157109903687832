import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormGroup } from '@angular/forms';

import { FileSaverService } from 'ngx-filesaver';
/*import { DeviceDetectorService } from 'ngx-device-detector';*/

import { environment } from '@env/environment';


@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    constructor(
        private router: Router,
        private fileSaverService: FileSaverService,
        @Inject(PLATFORM_ID) private platformId: any
    ) { }

    //
    // ─── ARRAY/OBJECT - METHODS ─────────────────────────────────────────────────────
    //

    arrayToObject(array: any, keyField: string): any {
        return array.reduce((obj: any, item: any) => {
            obj[item[keyField]] = item;
            return obj;
        }, {});
    }

    objectToArray(object: any): any[] {
        return Object.keys(object).map(i => object[i]);
    }

    objectToObjectWithNewKey(object: any, keyField: string): any {
        return this.arrayToObject(Object.keys(object).map(i => object[i]), keyField);
    }

    sortArray(array: any[], propertyToSort: string): any[] {
        return array.sort((a: any, b: any) => {
            if (a[propertyToSort] < b[propertyToSort]) {
                return -1;
            } else if (a[propertyToSort] > b[propertyToSort]) {
                return 1;
            }
            return 0;
        });
    }

    sortReverseArray(array: any, propertyToSort: string): any[] {
        return array.sort((a: any, b: any) => {
            if (a[propertyToSort] > b[propertyToSort]) {
                return -1;
            } else if (a[propertyToSort] < b[propertyToSort]) {
                return 1;
            }
            return 0;
        });
    }

    removeUndefinedFromObject(object: any): any {
        Object.keys(object).forEach(
            (key: string) => {
                object[key] === undefined || object[key] === '' ? delete object[key] : '';
            }
        );
        return object;
    }

    removeUndefinedAndNullFromObject(object: any): any {
        Object.keys(object).forEach(
            (key: string) => {
                return object[key] === undefined || object[key] === null || object[key] === '' ? delete object[key] : ''
            })
            ;
        return object;
    }

    groupByKey(list: any[], key: string, omitKey: boolean = false): Record<string, any> {
        return list.reduce((hash, { [key]: value, ...rest }) => ({
            ...hash,
            [value]: (hash[value] || []).concat(omitKey ? { ...rest } : { [key]: value, ...rest })
        }), {});
    }

    //
    // ─── FILE UTILS - METHODS ───────────────────────────────────────────────────────
    //

    base64ToArrayBuffer(base64: string): Uint8Array {
        const binaryString = window.atob(base64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    saveByteArray(name: any, mimeType: string, base64: any): void {
        const byte = this.base64ToArrayBuffer(base64);
        const blob = new Blob([byte], { type: mimeType });
        this.fileSaverService.save(blob, name);
    }

    saveFileUri(name: string, uri: string): void {
        const link = document.createElement('a');
        link.href = `${environment.apiUrl}/${uri}`;
        link.download = name;
        link.target = '_blank';
        link.click();
    }

    //
    // ─── FORM UTILS - METHODS ───────────────────────────────────────────────────────
    //

    /**
     * Marks all controls in a form group as touched
     * @param formGroup - The form group to touch
     */
    markFormGroupTouched(formGroup: FormGroup): void {
        (Object as any).values(formGroup.controls).forEach((control: any) => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    moveItemInFormArray(
        formArray: FormArray,
        fromIndex: number,
        toIndex: number
    ): void {
        const dir = toIndex > fromIndex ? 1 : -1;

        const item = formArray.at(fromIndex);
        for (let i = fromIndex; i * dir < toIndex * dir; i = i + dir) {
            const current = formArray.at(i + dir);
            formArray.setControl(i, current);
        }
        formArray.setControl(toIndex, item);
    }


    //
    // ─── PWA UTILS - METHODS ────────────────────────────────────────────────────────
    //

    isInStandaloneMode(): boolean {
        return (window.matchMedia('(display-mode: standalone)').matches);
    };

    //
    // ─── METHODS ────────────────────────────────────────────────────────────────────
    //

    getCurrentPath(): string {
        return this.router.url.substr(1);
    }

    getNumberValue(value: string | number): number {
        if (typeof value === 'string') {
            if (isNaN(+value)) {
                return +value.replace(/\./g, '').replace(/\,/g, '.');
            } else {
                if (value.indexOf('.') !== -1 && value.split('.')[1].length > 2) {
                    return +value.replace(/\./g, '');
                } else {
                    return +value;
                }
            }
        } else {
            return value;
        }
    }

}
