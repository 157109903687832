import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { MaterialModule } from '@app/material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
//
// ─── COMPONENT ──────────────────────────────────────────────────────────────────
//
import { LayoutLoginComponent } from './layouts/layout-login.component';
import { LayoutPagesComponent } from './layouts/layout-pages.component';
import { HeaderComponent } from './components/header/header.component';

import { ResultPopupComponent } from './components/result-popup/result-popup.component';
import { InputFieldEditDialogComponent } from './components/input-field-edit-dialog/input-field-edit-dialog.component';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';

@NgModule({
    declarations: [
        LayoutLoginComponent,
        LayoutPagesComponent,
        HeaderComponent,
        ResultPopupComponent,
        InputFieldEditDialogComponent,
        OnlyNumberDirective,
        ConfirmationDialogComponent,
        AlertDialogComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        MaterialModule,
        FlexLayoutModule
    ],
    exports: [
        LayoutPagesComponent,
        HeaderComponent,
        ResultPopupComponent,
        OnlyNumberDirective
    ]
})
export class SharedModule { }
