import { Component, Inject, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'cvn-result-popup',
    templateUrl: './result-popup.component.html',
    styleUrls: ['./result-popup.component.scss']
})
export class ResultPopupComponent implements OnInit {

    public type!: string;
    public message: string = '';

    constructor(
        public dialogRef: MatDialogRef<ResultPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        this.message = this.data.message;
        this.type = this.data.type;
    }

    close(): void {
        this.dialogRef.close();
    }

}
