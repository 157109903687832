<div mat-dialog-actions fxLayoutAlign="end">
    <mat-icon color="primary" class="icon-close-modal" (click)="close()">clear</mat-icon>
</div>
<div class="cvn-dialog_content" mat-dialog-content fxLayout="row" fxLayoutAlign="start center">
    <ng-container *ngIf="type === 'success'; else error">
        <mat-icon color="primary">check_circle_outline</mat-icon>
        Operazione avvenuta con successo!
    </ng-container>
    <ng-template #error>
        <mat-icon color="warn">error</mat-icon>
        Si è verificato un errore!
    </ng-template>
</div>
<div class="warn-message" *ngIf="message != ''">{{message}}</div>
