import { HttpClientModule } from '@angular/common/http';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from '@app/core/core.module';
import { SharedModule } from './shared/shared.module'
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { FileSaverModule } from 'ngx-filesaver';
import { IConfig, NgxMaskModule } from 'ngx-mask';

const maskConfig: Partial<IConfig> = {
    validation: true,
  };


registerLocaleData(localeIt);
@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        CoreModule,
        SharedModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgProgressModule.withConfig({
            spinnerPosition: 'right',
            color: 'red'
        }),
        NgProgressHttpModule,
        FileSaverModule,
        NgxMaskModule.forRoot(maskConfig),
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'it-IT' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
