<h1 mat-dialog-title>{{dialogTitle}}</h1>

<div mat-dialog-content>
    <mat-form-field appearance="outline">
        <mat-label>Testo aggiuntivo</mat-label>
        <textarea *ngIf="dialogTitle == 'Note riga ordine di vendita'" matInput cdkTextareaAutosize maxlength="60" #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="10" [(ngModel)]="text"></textarea>
        <textarea *ngIf="dialogTitle != 'Note riga ordine di vendita'" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="10" [(ngModel)]="text"></textarea>
    </mat-form-field>
</div>

<div mat-dialog-actions fxLayoutAlign="end">
    <button mat-button (click)="onCancel()">Annulla</button>
    <button mat-button (click)="onConfirm()" cdkFocusInitial>Modifica</button>
</div>
