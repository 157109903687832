import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'cvn-input-field-edit-dialog',
    templateUrl: './input-field-edit-dialog.component.html',
    styleUrls: ['./input-field-edit-dialog.component.scss']
})
export class InputFieldEditDialogComponent implements OnInit {
    public dialogTitle!: string;
    public text!: string;

    constructor(
        private dialogRef: MatDialogRef<InputFieldEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        this.dialogTitle = this.data.dialogTitle || 'Modifica';
        this.text = this.data.textToEdit || '';
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onConfirm(): void {
        this.dialogRef.close(this.text);
    }

}
