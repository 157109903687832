import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError, Observable, Subject, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // Handle request
        return next.handle(request).pipe(
            catchError((error: any) => {
                if (
                    (error.status === 401 || error.status === 403) &&
                    !request.url.includes('login') &&
                    !request.url.includes('logout') &&
                    !request.url.includes('token/refresh')
                ) {
                    localStorage.removeItem('spartaId');
                    window.location.href = environment.loginPage;
                    return EMPTY;
                }
                return throwError(error);
            })
        );
    }
}
