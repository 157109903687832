import { Component } from '@angular/core';

@Component({
    selector: 'cvn-layout-pages',
    template: `
        <cvn-header></cvn-header>
        <router-outlet></router-outlet>
    `,

    styles: []
})
export class LayoutPagesComponent { }
