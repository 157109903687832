<mat-toolbar class="cvn-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="70px">
    <a routerLink="/">
        <img width="110" height="36" alt="logo" src="/assets/images/covisian.svg">
    </a>
    <a routerLink="/post-revenues" routerLinkActive="cvn-active-item" mat-button>
        REVENUE POSTING
    </a>
    <a routerLink="/invoicing" routerLinkActive="cvn-active-item" mat-button>
        INVOICING
    </a>
    <a routerLink="/invoiced" routerLinkActive="cvn-active-item" mat-button>
        INVOICED
    </a>
</mat-toolbar>
