import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subscriber } from 'rxjs';

import { NgxXml2jsonService } from 'ngx-xml2json';

import { environment } from '@env/environment';

import { Constants } from '../constants/constants';
import { RevenuePosting } from '@app/shared/models/revenue-posting.model';

import * as js2xml from 'js2xmlparser';
import * as moment from 'moment';
import { UtilsService } from './base/utils.service';

type allowedRevenuePostingItemAttributes = 'revenues' | 'revenuesLocal' | 'totalPieces' | 'totalHours';

@Injectable({
    providedIn: 'root'
})
export class RevenuesService {

    constructor(
        private httpClient: HttpClient,
        private ngxXml2jsonService: NgxXml2jsonService,
        private utilsService: UtilsService
    ) { }

    getUser(): Observable<any> {
        const options = { withCredentials: true };
        return this.httpClient.get<any>('users/me', options);
    }

    getRevenuePostingList(companyId: string, corporateId: number, businessLine: string | null = null,
        billingCustomerCode: string | null = null, endDate: string | null = null): Observable<any> {
        const options = { withCredentials: true };
        const params = {
            idCompany: companyId,
            idCorporate: corporateId,
            businessLine: businessLine,
            endDate: moment(endDate).format('YYYY-MM-DD'),
            billingCustomerCode: billingCustomerCode,
            customerCode: null
        };
        return this.httpClient.post<any>(environment.SPARTA_BILLING_LIST, params, options);
    }

    saveSellingOrder(order: any, revenuePosting: RevenuePosting, endDate: any, companyId: string, contract: string): Observable<any> {
        const options = { withCredentials: true };
        const params = {
            endDate: moment(endDate).format('YYYY-MM-DD'),
            billingCustomerCode: revenuePosting.billingCustomerCode,
            billingServiceCode: revenuePosting.billingServiceCode,
            extContractCode: contract,
            finalRevenuesLocal: revenuePosting.details.length
                ? revenuePosting.revenuesLocal
                : this.utilsService.getNumberValue(revenuePosting.revenuesLocalPost),
            currencyCode: revenuePosting.currencyCode,
            finalRevenues: revenuePosting.details.length
                ? ((+revenuePosting.revenuesLocal) * (+revenuePosting.changeRate))
                : (this.utilsService.getNumberValue(revenuePosting.revenuesLocalPost) * (+revenuePosting.changeRate)),
            sellingOrderNumber: order,
            idCorporate: 1,
            idCompany: companyId,
            requesterIdSparta: localStorage.getItem('spartaId'),
            trxTimeStamp: moment().format('YYYY-MM-DD HH:mm:ss.SSS')
        };
        return this.httpClient.put<any>(environment.SPARTA_SAVE_SELLING_ORDERS, params, options);
    }

    postRevenues(idCompanyExt: string, body: any): Observable<any> {
        const options = {
            declaration: {
                include: false
            },
            wrapArray: {
                include: false
            }
        };
        const xml = Constants.XML_STRIP_REGEX.exec(js2xml.parse('CovisianSalesContract', body, options));
        const soapBody = xml ? xml[1] : '';

        return new Observable((subscriber: Subscriber<any>) => {
            const xmlhttp = new XMLHttpRequest();
            const sr = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:cov="http://www.infor.com/businessinterface/CovisianSalesContract"><soapenv:Header><cov:Activation><company>${idCompanyExt}</company></cov:Activation></soapenv:Header><soapenv:Body><cov:createSalesOrder><createSalesOrderRequest><ControlArea><processingScope>request</processingScope></ControlArea><DataArea>${soapBody}</DataArea></createSalesOrderRequest></cov:createSalesOrder></soapenv:Body></soapenv:Envelope>`;
            xmlhttp.onreadystatechange = () => {
                if (xmlhttp.readyState === 4) {
                    const parser = new DOMParser();
                    if (xmlhttp.status === 200) {
                        const xml = parser.parseFromString(xmlhttp.responseText, 'text/xml');
                        const obj = this.ngxXml2jsonService.xmlToJson(xml) as any;
                        const salesOrders = obj['S:Envelope']['S:Body']['createSalesOrderResponse']['DataArea']['CovisianSalesContract'];
                        subscriber.next(salesOrders);
                        subscriber.complete();
                    } else {
                        const xml = parser.parseFromString(xmlhttp.responseText, 'text/xml');
                        const obj = this.ngxXml2jsonService.xmlToJson(xml) as any;
                        subscriber.error(obj['S:Envelope']['S:Body']['S:Fault']);
                    }
                }
            }
            xmlhttp.open('POST', `${environment.apiUrl}/${environment.LN_ITEM}`, false);
            xmlhttp.setRequestHeader('Content-Type', 'text/xml; charset=utf-8');
            xmlhttp.setRequestHeader('Referrer-Policy', 'origin');
            xmlhttp.setRequestHeader('SOAPAction', 'createSalesOrder');
            xmlhttp.withCredentials = true;
            xmlhttp.send(sr);
        });
    }

    calculateTotal(revenuePostingArray: RevenuePosting[], attribute: allowedRevenuePostingItemAttributes): number {
        return revenuePostingArray
            .map((item: RevenuePosting) => item[attribute])
            .reduce((accumulator: number, revenue: number) => accumulator + revenue, 0);
    }

}

