import { Component, OnInit } from '@angular/core';
import { RevenuesService } from './core/services/revenues.service';

@Component({
    selector: 'cvn-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'covisian';
    startedClass = false;
    completedClass = false;
    preventAbuse = false;

    constructor(
        private revenuesService: RevenuesService,
    ) { }

    ngOnInit(): void {
        if (!localStorage.getItem('spartaId')) {
            this.revenuesService.getUser().subscribe((user: any) => {
                localStorage.setItem('spartaId', user.user.facets.sparta.idSparta);
            });
        }
    }

    onStarted(): void {
        this.startedClass = true;
        setTimeout(() => {
            this.startedClass = false;
        }, 800);
    }

    onCompleted(): void {
        this.completedClass = true;
        setTimeout(() => {
            this.completedClass = false;
        }, 800);
    }
}
