import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutPagesComponent } from './shared/layouts/layout-pages.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'post-revenues',
    },
    {
        path: '',
        component: LayoutPagesComponent,
        children: [
            {
                path: 'post-revenues',
                loadChildren: () => import('./features/post-revenues/post-revenues.module').then(m => m.PostRevenuesModule)
            },
            {
                path: 'invoicing',
                loadChildren: () => import('./features/invoicing/invoicing.module').then(m => m.InvoicingModule)
            },
            {
                path: 'invoiced',
                loadChildren: () => import('./features/invoiced/invoiced.module').then(m => m.InvoicedModule)
            },
        ]
    },
    {
        path: '**',
        redirectTo: 'post-revenues'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
