<h1 mat-dialog-title>{{data.dialogTitle}}</h1>

<div mat-dialog-content>
    <p>{{data.dialogBody}}</p>
    <p *ngIf="data.message">LN: {{data.message}}</p>
</div>

<div align="end" mat-dialog-actions>
    <button mat-button mat-dialog-close>
        OK
    </button>
</div>
